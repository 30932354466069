body {
  font-family: 'Libre Baskerville', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  color: #4c4b4b
  }

.header {
  background-image: url("/Users/laurentyson/Development/Elizabeth_code/elizabeth-bell-books/src/images/header-image.jpeg");
  height: 600px;
}

.headerName {
  font-family: 'Amarante', cursive;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
  font-size: 6rem;
  color: #C6AB64;
  margin-bottom: .5rem;
  margin-top: 2rem;
}

.headerText {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 1.5rem;
}

.headerButtons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 4rem;
  column-gap: 20rem;
}

.goldButtons {
  background-color: #C6AB64;
  border: none;
  color: #4c4b4b;
  padding: 17px 30px;
  font-size: 1.1rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 25px;
}

.navBar {
  display: flex;
  justify-content: center;
  padding-top: 100px;
}

nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

nav a {
  display: inline-bock; 
  margin: 1rem;
  text-decoration: none;
  font-size: 1.1rem;
  color: #4B4A4A;
  transition: 0.1s;
  
}

nav a:hover {  
  border-bottom: 2px solid #737373;  
}

.footer {
  display: flex;
  justify-content: center;
  column-gap: 50rem;
}

.footerText {
  font-size: 1rem;
}

.share {
  padding-top: 1.5rem;
  padding-bottom: 1rem;
}

.shareButton {
  background-color: #FF7F50;
  border: none;
  color: white;
  padding: 15px 24px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  border-radius: 7px;
}

.footerBackground {
  background-color: #C79FA2;
  padding: 1.5rem 0rem 1.5rem 0rem;
}

.authorNoteTitle {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 2.7rem;
}

.authorNote {
  display: flex;
  justify-content: center;
  margin: 4rem 10rem 10rem 10rem;
  column-gap: 2rem;
}

.aboutHeadshotImg,
.aboutHeadshotImgE,
.authorNoteImg {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 4px;
  padding: 5px;
  width: 325px;
}

.authorNoteChild-2 {
  font-size: 1.1rem;
  line-height: 2;
}

.textLink {
  color: #C6AB64;
}

.signature {
  font-family: 'Allura', cursive;
  font-size: 3rem;
  margin: 1rem
}

.purchaseNote {  
  background-color: #C79FA2;
  color: #FFFFFF;
}

.purchaseNoteTitle {
  font-size: 3rem;
  padding-top: 5rem;
  margin: 0rem 0rem 2rem 15rem;
}

.purchaseNoteChild-1 {
  font-size: 1.1rem;
  line-height: 2;
  display: flex;
  justify-content: center;
  padding-left: 20rem;
  padding-right: 20rem;
  text-align: center;  
}

.purchaseNoteButton {  
  padding-top: 2rem;
  width: 55%;
}

.purchaseNoteChild-2 {
  font-size: 1.1rem;
  line-height: 2;
  text-align: center;
  padding-left: 20rem;
  padding-right: 20rem;
}

.purchaseNoteChild-3 {
  display: flex;
  justify-content: center;
  padding-bottom: 3rem;
  column-gap: 20rem;  
}

.littleDragons{
  display: flex;
  justify-content: center;
  margin: 6rem;
  column-gap: 5rem;
}

.outlineButton{  
  border: 1px solid #C6AB64;
  color: #C6AB64;
  padding: 17px 30px;
  font-size: 1.1rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 25px;
}

.littleDragonsTextButton {
  text-align: center;
  width: 40rem;
  line-height: 2;  
}

.littleDragonsTitle{
  font-size: 2rem;
}

.playtimeCover{
  width: 20rem;
  padding-top: 3rem;  
}

.testimonialSection{  
  margin: 6rem 25rem 6rem 25rem;
}

.testimonialsTitle {
  font-size: 2rem;
  text-align: center;
}

.quote {
  padding-top: 2rem;
  height: 3.5rem;
  width: 5rem;
}

.testimonial {
  display: flex;
  justify-content: center;
  text-align: center;
  line-height: 2;
  column-gap: 1rem;
}

.events{
  text-align: center;
  line-height: 2;
  margin: 6rem;
}

.eventsTitle{
  font-size: 2rem;
}

.gallery {  
  text-align: center;
  margin: 5rem;
}

.timeGallery {
  padding-bottom: 5rem;
}

.previousEventsTitle,
.timeGalleryTitle {
  font-size: 2.7rem;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 4px;
}

.column {
  flex: 25%;
  max-width: 25%;
  padding: 0 4px;
}

.column img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
}

.container {
  position: relative;
  width: 100%;
}

.image {
  display: block;
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: #4c4b4b;
}

.container:hover .overlay {
  opacity: .85;
}

.text {
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.bookstore {
  text-align: center;
  padding: 5rem;   
}

.bookstoreTitle{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  line-height: 1.5;
  font-size: 1.3rem;
}

.bookstoreWrap {
  display: flex;
  justify-content: center;
  column-gap: 6rem;
  padding: 2rem;  
}

.bookstoreGallery img {
  width: 25rem;
  height: 25rem;
  margin: 1rem;
}

.bookstoreGallery{
 padding-bottom: 5rem;
}

.desc {
  font-size: 1.2rem;
}

.aboutBackground {
  background-image: url("/Users/laurentyson/Development/Elizabeth_code/elizabeth-bell-books/src/images/about-bgImage.jpeg");
  background-size: cover;
}
.about {
  text-align: center;
  padding: 4rem 10rem 10rem 10rem;
}

.aboutTitle {
  font-size: 2.25rem;
  color: black;
}

.aboutText {
  font-size: 1.1rem;
  line-height: 2;
  color: black;
}

.elizabethAboutDetails {
  padding-bottom: 6rem;
}

.contact {
  text-align: center;
  margin-bottom: 15rem;
  padding: 4rem 10rem 10rem 10rem;
}

.contactTitle {
  font-size: 3rem;
  margin-top:0px;
  text-align: center;
}

.contactText {  
  text-align: center;
  line-height: 1.5;
  font-size: 1.1rem;
  padding-bottom: 2rem;
}

form {
  max-width: 600px;
  text-align: center;
  font-size: .75rem;
  margin: auto;
}

.fields, select, textarea {
  width: 100%;
  font-family: 'Libre Baskerville', serif;
  font-size: 1rem;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 26px;
  resize: vertical 
}

textarea {
  height: 126px;  
}

.submit {
  background-color: #C6AB64;
  font-family: 'Libre Baskerville', serif;
  font-weight: bold;
  width: 40%;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 7px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
}

.submit:hover {
  background-color: #737373;  
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
}

.successMessage {
  color: #5cbd67;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  padding-top: 1.2rem;
}

.time{
  height: 15rem;
  width: 30rem;
}

@media screen and (max-width: 1290px) {
  .footer {  
    flex-wrap: wrap;  
  }
  .previousEventsTitle,
  .timeGalleryTitle,
  .authorNoteTitle {    
    font-size: 2.4rem;
  }
  .authorNote {    
    margin: 4rem;
  }
  .authorNoteChild-2 {
    font-size: 1rem;
    line-height: 1.3;
  }
  .aboutHeadshotImg,
  .authorNoteImg {    
    width: 300px;
    height: 300px;
  }
  .aboutHeadshotImgE {    
    width: 300px;
    height: 400px;
  }
  .purchaseNoteTitle {    
    margin: 0rem 0rem 2rem 5rem;
  }
  .purchaseNoteChild-1 {    
    padding-left: 4rem;
    padding-right: 2rem;
  }
  .purchaseNoteButton {  
    padding-top: 3.5rem;
    width: 100%;
  }
  .purchaseNoteChild-2 {    
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .purchaseNoteChild-3 {    
    column-gap: 10rem;  
  }
  .playtimeCover {    
    width: 300px;
    height: 300px;
    padding-top: 4rem;
  }
  .testimonialSection{    
    margin: 0rem 10rem 0rem 10rem;
  }
}

@media screen and (max-width: 976px) {
  .footer {        
    column-gap: 37rem;  
  }    
  .littleDragons{
    flex-wrap: wrap;
    margin: 2rem;
  }
  .playtimeCover {        
    padding-top: 1.5rem;
  }
  .testimonialSection{    
    margin: 0rem 7rem 0rem 7rem;
  }
  .quote {
    padding-top: 5rem;
    height: 2.5rem;
    width: 4rem;
  }
  .gallery {     
    margin: 6rem 0rem 6rem 0rem;
  }
  .about {    
    padding: 2rem;
  }
}

@media screen and (max-width: 820px) {
  .footer {        
    column-gap: 30rem;  
  }
  .aboutHeadshotImg {    
    width: 250px;
    height: 250px;
  } 
  .aboutHeadshotImgE {    
    width: 250px;
    height: 325px;
  } 
}

@media screen and (max-width: 768px) {
  .footer {        
    column-gap: 25rem;  
  }  
  .previousEventsTitle,
  .timeGalleryTitle,
  .authorNoteTitle {    
    font-size: 2.2rem;
  }
  .headerButtons {    
    margin-top: 3rem;
    column-gap: 10rem;
  }
  .authorNote {    
    margin: 2rem;
  }  
  .authorNoteImg {    
    width: 250px;
    height: 250px;
  }
  .authorNoteChild-2 {
    font-size: 1rem;
    line-height: 1.5;
  }
  .purchaseNoteTitle {    
    margin: 0rem 0rem 2rem 5rem;
  }
  .purchaseNoteChild-2 {    
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .testimonialsTitle {
    font-size: 2rem;
  }
  .testimonialSection{    
    margin: 0rem 2rem 0rem 2rem;
  }
  .bookstoreWrap {
    flex-wrap: wrap;  
  }  
}

@media screen and (max-width: 590px) {
  .headerName {    
    font-size: 4rem;    
  }  
  .headerText {
    font-size: 1.13rem;
  }
  .headerButtons {    
    margin-top: 3rem;
  }
  .footer {        
    column-gap: 3rem;  
  }  
  .authorNote {
    flex-wrap: wrap;
  }
  .previousEventsTitle,
  .timeGalleryTitle,
  .authorNoteTitle {    
    font-size: 1.7rem;
  }  
  .authorNoteImg {    
    width: 175px;
    height: 200px;
  }
  .authorNoteChild-2 {
    margin-top: 1rem;
  }
  .purchaseNoteTitle {  
    font-size: 2.5rem;  
    margin: 0rem 0rem 2rem 4rem;
  }
  .purchaseNoteChild-1 {    
    padding-left: 2rem;
    flex-wrap: wrap;
  }
  .purchaseNoteButton {  
    padding-top: .4rem;
    padding-bottom: .4rem;
    width: 180%;
  }
  .purchaseNoteChild-2 {    
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .purchaseNoteChild-3 {    
    flex-wrap: wrap;
  }
  .littleDragons{    
    margin: 4rem;
  }
  .littleDragonsTitle{
    line-height: 1.2;
  }  
  .testimonialSection{    
    margin: 0rem 2rem 0rem 2rem;
  }  
  .testimonial {    
    column-gap: .5rem;
  }
  .quote {
    padding-top: 12rem;
    height: 1.7rem;
    width: 2.2rem;
  }
  .column {
    flex: 50%;
    max-width: 70%;
  }  
  .aboutHeadshotImg {    
    width: 175px;
    height: 175px;
  }
  .aboutHeadshotImgE {    
    width: 200px;
    height: 230px;
  }
  .aboutTitle {
    font-size: 2rem;
  }
  .contact {    
    padding: 2rem;
  }
  .contactTitle {
    font-size: 2rem;    
  }
  .time{
    height: 10rem;
    width: 20rem;
  }
}

@media screen and (max-width: 393px) {
  .header {    
    height: 675px;
  }
  .headerName {    
    font-size: 3.5rem;    
  }  
  .headerText {
    font-size: 1.05rem;
  }
  .headerButtons {    
    margin-top: 3rem;
  }
  .goldButtons {   
    font-size: 1rem;
    margin: 6px 2px;    
  }
  .purchaseNoteTitle {  
    margin: 0rem 0rem 2rem 3rem;
  }
  .littleDragons{    
    margin: 2rem;
  }
  .testimonialSection{    
    margin: 0rem 1rem 0rem 1rem;
  }  
}
